const pondStatus = {
  en: {
    alertStatistics: ' Alert Summary',
    bindPondToLayout: 'Bind Pond to Layout',
    bindMachinePosition: 'Bind Machine Position',
    bindSensorPosition: 'Bind Sensor Position',
    pondName: 'Pond Name',
    sensorAlert: 'Sensor Alert',
    sensorNormal: 'Sensor Normal',
    fanError: 'Fan Error',
    fanNormalImg: 'Fan Normal (Image)',
    fanAlert: 'Fan Alert',
    fanNormal: 'Fan Normal'
  },
  zh: {
    alertStatistics: '各类数据',
    bindPondToLayout: '绑定塘口到布局',
    bindMachinePosition: '绑定机器位置',
    bindSensorPosition: '绑定传感器位置',
    pondName: '塘口名称',
    sensorAlert: '传感器掉线动图',
    sensorNormal: '传感器正常动图',
    fanError: '增氧掉线动图',
    fanNormalImg: '增氧正常静态图片',
    fanAlert: '增氧告警动态图片',
    fanNormal: '增氧正常动态图片'
  }
}

export default pondStatus
