<template>
  <div id="app">
    <div class="language-switcher">
      <span :class="{ active: language === 'zh' }" @click="setLanguage('zh')">中文</span> |
      <span :class="{ active: language === 'en' }" @click="setLanguage('en')">ENG</span>
    </div>
    <router-view />
  </div>
</template>

<script>
  export default {
    computed: {
      language() {
        return this.$store.state.language
      }
    },
    methods: {
      setLanguage(lang) {
        this.$store.dispatch('setLanguage', lang).then(() => {
          location.reload()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #app {
    width: 100vw;
    height: 100vh;
    background-color: #020308;
    overflow: hidden;
  }
  .language-switcher {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .language-switcher span {
    cursor: pointer;
    color: gray;
  }
  .language-switcher span.active {
    color: white;
  }
</style>
