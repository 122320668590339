import common from './commonLocales'
import dashboard from './dashboardLocales'
import pondStatus from './pondStatusLocales'
import waterQualityStatAlert from './waterQualityStatAlertLocales'
import waterStat from './waterStatLocales'

const messages = {
  en: {
    ...common.en,
    ...dashboard.en,
    ...pondStatus.en,
    ...waterQualityStatAlert.en,
    ...waterStat.en
  },
  zh: {
    ...common.zh,
    ...dashboard.zh,
    ...pondStatus.zh,
    ...waterQualityStatAlert.zh,
    ...waterStat.zh
  }
}

export default messages
