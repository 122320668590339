import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import VueI18n from 'vue-i18n'
import messages from './locales'

import { Button, Dialog, Message, Select, Option } from 'element-ui'

// 引入全局css
import './assets/scss/style.scss'
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/chart-bar.js'
import 'vue-awesome/icons/chart-area.js'
import 'vue-awesome/icons/chart-pie.js'
import 'vue-awesome/icons/chart-line.js'
import 'vue-awesome/icons/align-left.js'
//引入echart
//4.x 引用方式
// import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// 引入element-ui
Vue.component(Dialog.name, Dialog)
Vue.component(Button.name, Button)
Vue.prototype.$message = Message
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)

// 全局注册
Vue.component('icon', Icon)
Vue.use(dataV)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.state.language,
  messages
})

store.watch(
  state => state.language,
  newLang => {
    i18n.locale = newLang
  }
)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
