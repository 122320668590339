const waterStat = {
  en: {
    waterTemperature: 'Water Temperature',
    dissolvedOxygen: 'Dissolved Oxygen',
    ammonianitrogen: 'NH3',
    nitrite: 'NO2',
    ph: 'pH'
  },
  zh: {
    waterTemperature: '水温',
    dissolvedOxygen: '溶氧',
    ammonianitrogen: '氨氮',
    nitrite: '亚硝酸盐',
    ph: 'pH值'
  }
}

export default waterStat
