import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    pondAlerts: [],
    deviceAlerts: [],
    language: localStorage.getItem('language') || 'en'
  },
  mutations: {
    // Mutation to update pondAlerts
    SET_POND_ALERTS(state, payload) {
      state.pondAlerts = payload
    },
    SET_LANGUAGE(state, language) {
      state.language = language
      localStorage.setItem('language', language)
    },
    // Mutation to update deviceAlerts
    SET_DEVICE_ALERTS(state, payload) {
      state.deviceAlerts = payload
    }
  },
  actions: {
    // Action to process data and commit to the mutation
    processAlerts({ commit }, data) {
      commit('SET_POND_ALERTS', data)
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    processDeviceAlerts({ commit }, data) {
      commit('SET_DEVICE_ALERTS', data)
    }
  },
  modules: {}
})
