const common = {
  en: {
    smartFarmingPlatform: 'Smart Farming platform',
    planning: 'Planning',
    marketing: 'Marketing',
    operation: 'Operation',
    production: 'Production',
    basicInfo: 'Basic Info',
    monthlyAlerts: 'Monthly Alerts',
    dailyAlerts: 'Daily Alerts',
    mechanicsAlerts: 'Mechanics Alerts',
    waterAlert: 'Water Alert',
    times: 'times',
    exitEdit: 'Exit',
    edit: 'Edit',
    createLayout: 'Create Layout',
    bindPond: 'Bind Pond',
    unbindPond: 'Unbind Pond',
    deletePage: 'Delete Page',
    confirmDelete: 'Are you sure you want to delete this page?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    addPage: 'Add Page',
    editPond: 'Edit Pond',
    selectPond: 'Select Pond',
    bindMachine: 'Bind Machine',
    bindSensor: 'Bind Sensor',
    rows: 'Rows',
    columns: 'Columns',
    noData: 'No Data',
    loading: 'Loading...'
  },
  zh: {
    smartFarmingPlatform: '现代产业园智慧渔业平台',
    planning: '规划一张图',
    marketing: '经营一张表',
    operation: '运营一张网',
    production: '生产一张表',
    basicInfo: '基地情况',
    monthlyAlerts: '月报警量',
    dailyAlerts: '日预警量',
    mechanicsAlerts: '机电报警',
    waterAlert: '水质报警',
    times: '次',
    exitEdit: '退出编辑',
    edit: '编辑',
    createLayout: '创建布局',
    bindPond: '绑定鱼塘',
    unbindPond: '解绑塘口',
    deletePage: '删除页面',
    confirmDelete: '确定要删除这个页面吗？',
    cancel: '取消',
    confirm: '确定',
    addPage: '添加页面',
    editPond: '编辑塘口',
    selectPond: '请选择塘口',
    bindMachine: '绑定机器位置',
    bindSensor: '绑定传感器位置',
    rows: '行数',
    columns: '列数',
    noData: '暂无数据',
    loading: '加载中...'
  }
}

export default common
