const dashboard = {
  en: {
    pondSummary: 'Pond Summary',
    pondArea: 'Pond Area',
    totalArea: 'Total Area',
    other: 'Other',
    acres: 'Acres',
    monthlyAlerts: 'Monthly Alerts',
    dailyAlerts: 'Daily Alerts',
    mechanicsAlerts: 'Mechanics Alerts',
    waterAlert: 'Water Alert',
    times: 'times',
    pondName: 'Pond Name',
    sensorAlert: 'Sensor Alert',
    sensorNormal: 'Sensor Normal',
    fanError: 'Fan Error',
    fanNormalImg: 'Fan Normal (Image)',
    fanAlert: 'Fan Alert',
    fanNormal: 'Fan Normal',
    machinery: 'Machinery'
  },
  zh: {
    pondSummary: '基塘-塘口统计',
    pondArea: '塘口面积',
    totalArea: '塘口总面积',
    other: '其他',
    acres: '亩',
    monthlyAlerts: '月报警量',
    dailyAlerts: '日预警量',
    mechanicsAlerts: '机电报警',
    waterAlert: '水质报警',
    times: '次',
    pondName: '塘口名称',
    sensorAlert: '传感器掉线动图',
    sensorNormal: '传感器正常动图',
    fanError: '增氧掉线动图',
    fanNormalImg: '增氧正常静态图片',
    fanAlert: '增氧告警动态图片',
    fanNormal: '增氧正常动态图片',
    machinery: '塘口-渔机种类'
  }
}

export default dashboard
