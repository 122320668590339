const waterQualityStatAlert = {
  en: {
    offline: 'Offline',
    overvoltage: 'Overvoltage',
    aging: 'Aging',
    lackInput: 'Lack Input',
    lackOutput: 'Lack Output',
    phAbnormal: 'pH Abnormal',
    lowDO: 'Low DO',
    deadlineDO: 'Deadline DO',
    machinery: 'Machinery',
    aerators: 'Aerators',
    wheelMotor: 'Wheel Motor',
    pumps: 'Pumps',
    others: 'Others',
    allPositionSensor: 'Sensor',
    waterTemperature: 'Water Temperature',
    ph: 'pH',
    object: 'Object',
    alert: 'Alert',
    date: 'Date',
    occurTime: 'Occur Time',
    alertTime: 'Alert Time'
  },
  zh: {
    offline: '离线',
    overvoltage: '过压',
    aging: '回赔老化',
    lackInput: '输入缺相',
    lackOutput: '电机缺扫',
    phAbnormal: 'PH异常',
    lowDO: '低溶氧',
    deadlineDO: '功率异常',
    aerators: '填氧机',
    wheelMotor: '划水机',
    pumps: '水泵',
    others: '其他',
    allPositionSensor: '各测点数据',
    waterTemperature: '水温',
    ph: 'pH',
    object: '对象',
    alert: '告警',
    date: '日期',
    occurTime: '发生时间',
    alertTime: '告警时间'
  }
}

export default waterQualityStatAlert
